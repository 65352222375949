import React from 'react'
import LoadingBar from 'react-redux-loading-bar'
import { NotificationContainer } from 'react-notifications'

// Auth Component
import ForgotPassword from '../components/Auth/ForgotPassword'

const ForgotPasswordIndex = ({location, pageContext}) => {
    return (
        <>
            <NotificationContainer />
            <LoadingBar scope="sectionBar" />
            <ForgotPassword location={location} />
        </>
    );
}

export default ForgotPasswordIndex