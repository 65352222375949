import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import _ from 'lodash'
import { Link, navigate } from "gatsby"
import lang from '../../langs'
import { connect } from 'react-redux'
import { forgotPassword } from '../../actions/auth';
import { forgotValidate } from '../../validations/authValidate';
import { createNotification, pageTitle } from '../../utils/helpers'
import Layout from '../../components/layout'
import SEO from "../../components/seo"
import Logo from "../../components/Common/LogoDark"

class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            loading:false,
            success:false,
            errors: {}
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        if (this.props.auth.isAuthenticated) {
            navigate('/user/dashboard');
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.auth.isAuthenticated) {
            navigate('/user/dashboard');
        }
    }

    async onSubmit(e) {
        e.preventDefault();
        const userData = {
            email: this.state.email,
        };

        try {
            await forgotValidate(userData)
        } catch (err) {
            this.setState({ errors: err })
            createNotification('error', _.map(err, (val) => { return val }).join("\n\n\n"))
            return false;
        }

        this.setState({loading:true, errors:{}}, ()=>{
            this.props.forgotPassword({
                email:this.state.email
            })
                .then(res => {
                    createNotification('success', res.message)
                    this.setState({
                        success:true
                    })
                })
                .catch(err => {
                    if (err && typeof err.response !== undefined) {
                        const error = err.response
                        if (_.has(error, 'status') && (error.status == 400 ||
                            error.status == 401 ||
                            error.status == 422)) {
                        createNotification('warning', error.data.message)
                        } else {
                            createNotification('error', lang('error.server_unknown'))
                        }
                    } else {
                        createNotification('error', lang('error.server_unknown'))
                    }
                    
                    setTimeout(() => {
                        this.setState({
                            loading:false
                        })
                    }, 2000);
                })
        })
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });

        if (this.state.errors) {
            if (this.state.email && this.state.errors.email) {
                this.setState({ errors: { email: null } })
            }

            if (this.state.password && this.state.errors.password) {
                this.setState({ errors: { password: null } })
            }
        }
    }

    render() {
        const { errors } = this.state;
        return (
            <Layout withHeader={false}>
                <SEO title="Forgot Password Form" />
                <div id="bodyReegister">
                    <div style={{height:600}} className="align-items-center align-content-center justify-content-center w-auto-xs bg-white">
                        <div className="row justify-content-md-center">
                            <div className="col-4">
                                <div className="shadow rounded p-5 mt-3 mb-3 align-content-center justify-content-center  align-items-center" style={{width:400}}>
                                    <div className="text-color">
                                        <div className="text-center">
                                            <Logo mode="dark" />
                                        </div>
                                    
                                        
                                        <form name="formForgot" noValidate>
                                            {this.state.success == false ? (<>
                                                <div className="text-uppercase text-muted text-center mb-4 text-sm">
                                                    Please fill your email address
                                                </div>
                                                <div className="form-label-group mb-4">
                                                    <input
                                                        type="text"
                                                        name="email"
                                                        id="email"
                                                        placeholder="Email"
                                                        className={classnames('form-control form-control-lg', {
                                                            'is-invalid': errors.email
                                                        })}
                                                        onChange={this.onChange}
                                                        value={this.state.email}
                                                    />
                                                    {errors.email && <div className="invalid-feedback">{errors.email}.</div>}
                                                </div>
                                            
                                                <button type="button" id="forgotSubmit" disabled={this.state.loading ? true : false} onClick={this.onSubmit} className="btn btn-danger btn-block btn-lg">{this.state.loading ? 'Loading...' : 'Submit'}</button>
                                            </>) : (
                                                <>
                                                    <h4>Check Your Email...</h4>
                                                    <div className="alert alert-info">
                                                        Great! You just requested forgot password with Email Address, please check your email then Follow instruction on it!
                                                    </div>
                                                </>
                                            )}

                                            <div className="my-4 text-center">
                                                Back to <Link className="" to={'/login'}>Login</Link>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}

ForgotPassword.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    forgotPassword: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors,
    loaded: state.loadingBar.sectionBar == 1 ? true : false
});

export default connect(mapStateToProps, { forgotPassword })(ForgotPassword);
